import axios from '@/axios.js';
import moment from 'moment';
import apolloClient from '@/plugins/vue-apollo';
import { PROGRAMMED_SERVICES } from '@/graphql/queries';
import { UPDATE_PROGRAMMED_SERVICE } from '@/graphql/mutations';

export default {
  addEvent({ commit }, event) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/apps/calendar/events/', { event: event })
        .then((response) => {
          commit('ADD_EVENT', Object.assign(event, { id: response.data.id }));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchEvents({ commit }) {
    apolloClient
      .query({
        query: PROGRAMMED_SERVICES,
      })
      .then((response) => {
        if (response && response.data) {
          commit('SET_EVENTS', response.data.programmedServices);
        }
      });
  },
  fetchEventLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/apps/calendar/labels')
        .then((response) => {
          commit('SET_LABELS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async editEvent({ commit }, service) {
    const date =
      moment(service.programmed_at).format('YYYY-MM-DD') + ' ' + service.time;

    const { data } = await apolloClient.mutate({
      mutation: UPDATE_PROGRAMMED_SERVICE,
      variables: {
        id: service.id,
        programmed_at: date,
        color: service.color,
        deliveryman_id: service.deliverymanId,
      },
    });

    this._vm.$vs.notify({
      color: 'success',
      title: 'Servicio actualizado!',
      text: 'El servicio fue correctamente actualizado',
    });

    commit('UPDATE_EVENT', data.updateProgrammedService);
  },
  removeEvent({ commit }, eventId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/apps/calendar/event/${eventId}`)
        .then((response) => {
          commit('REMOVE_EVENT', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  eventDragged({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/apps/calendar/event/dragged/${payload.event.id}`, {
          payload: payload,
        })
        .then((response) => {
          // Convert Date String to Date Object
          let event = response.data;
          event.startDate = new Date(event.startDate);
          event.endDate = new Date(event.endDate);

          commit('UPDATE_EVENT', event);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
