var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simple-calendar",attrs:{"id":"simple-calendar-app"}},[_c('div',{staticClass:"vx-card no-scroll-content"},[_c('calendar-view',{ref:"calendar",staticClass:"theme-default",attrs:{"displayPeriodUom":_vm.calendarView,"show-date":_vm.showDate,"items":_vm.simpleCalendarEvents,"enableDragDrop":"","eventTop":_vm.windowWidth <= 400 ? '2rem' : '3rem',"eventBorderHeight":"0px","eventContentHeight":"1.65rem"},on:{"click-date":_vm.openAddNewEvent,"click-item":_vm.openEditEvent,"drop-on-date":_vm.eventDragged}},[_c('div',{staticClass:"mb-4",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"vx-row no-gutter"},[_c('div',{staticClass:"vx-col w-1/3 items-center sm:flex hidden"}),_c('div',{staticClass:"vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"},[_c('div',{staticClass:"flex items-center"},[_c('feather-icon',{staticClass:"cursor-pointer bg-primary text-white rounded-full",attrs:{"icon":_vm.$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon',"svgClasses":"w-5 h-5 m-1"},on:{"click":function($event){return _vm.updateMonth(-1)}}}),_c('span',{staticClass:"mx-3 text-xl font-medium whitespace-no-wrap"},[_vm._v(_vm._s(_vm._f("month")(_vm.showDate)))]),_c('feather-icon',{staticClass:"cursor-pointer bg-primary text-white rounded-full",attrs:{"icon":_vm.$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon',"svgClasses":"w-5 h-5 m-1"},on:{"click":function($event){return _vm.updateMonth(1)}}})],1)]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex justify-center"},[_vm._l((_vm.calendarViewTypes),function(view,index){return [(_vm.calendarView === view.val)?_c('vs-button',{key:String(view.val) + 'filled',staticClass:"p-3 md:px-8 md:py-3",class:{
                  'border-l-0 rounded-l-none': index,
                  'rounded-r-none': _vm.calendarViewTypes.length !== index + 1,
                },attrs:{"type":"filled"},on:{"click":function($event){_vm.calendarView = view.val}}},[_vm._v(_vm._s(view.label))]):_c('vs-button',{key:String(view.val) + 'border',staticClass:"p-3 md:px-8 md:py-3",class:{
                  'border-l-0 rounded-l-none': index,
                  'rounded-r-none': _vm.calendarViewTypes.length !== index + 1,
                },attrs:{"type":"border"},on:{"click":function($event){_vm.calendarView = view.val}}},[_vm._v(_vm._s(view.label))])]})],2)]),_c('div',{staticClass:"vx-row sm:flex hidden mt-4"},[_c('div',{staticClass:"vx-col w-full flex"})])])])],1),_c('vs-prompt',{staticClass:"calendar-event-dialog",attrs:{"title":"Detalle de servicio","accept-text":"Guardar","cance-text":"Descartar","button-cancel":"border","is-valid":_vm.validForm,"active":_vm.activePromptEditEvent},on:{"accept":_vm.editEvent,"update:active":function($event){_vm.activePromptEditEvent=$event}}},[(_vm.selectedService.sender)?_c('div',{staticClass:"mt-4 mb-5 flex flex-col"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Quién envía")]),_c('span',[_c('strong',[_vm._v("Servicio Nº:")]),_vm._v(" "+_vm._s(_vm.selectedService.id)+" ")]),_c('span',[_c('strong',[_vm._v("Cliente:")]),_vm._v(" "+_vm._s(_vm.selectedService.senderName)+" ")]),_c('span',[_c('strong',[_vm._v("Teléfono:")]),_vm._v(" "+_vm._s(_vm.selectedService.senderPhone)+" ")]),_c('span',[_c('strong',[_vm._v("Dirección:")]),_vm._v(" "+_vm._s(_vm.selectedService.senderAddress)+" ")])]):_vm._e(),(
        _vm.selectedService.directions && _vm.selectedService.directions.length > 0
      )?_c('div',{staticClass:"mt-4 mb-5 flex flex-col"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Direcciones")]),_vm._l((_vm.selectedService.directions),function(direction,index){return _c('span',{key:index},[_c('strong',[_vm._v("Dirección "+_vm._s(index + 1)+":")]),_vm._v(" "+_vm._s(direction.address)+" - "+_vm._s(direction.description)+". ")])})],2):_vm._e(),_c('div',{staticClass:"mt-4 mb-5 flex flex-col"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Datos generales")]),_c('span',[_c('strong',[_vm._v("Factura: ")]),(_vm.selectedService.invoice)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedService.invoice)+" ")]):_c('span',[_vm._v("N/A")])]),_c('span',[_c('strong',[_vm._v("Valor del pedido: ")]),(_vm.selectedService.order_value)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.selectedService.order_value))+" ")]):_c('span',[_vm._v("N/A")])])]),_c('div',{staticClass:"my-4"},[_c('small',{staticClass:"text-san-juan font-bold"},[_vm._v("Fecha")]),_c('datepicker',{attrs:{"language":_vm.langEs,"name":"start-date","disabledDates":_vm.disabledDatesTo},model:{value:(_vm.selectedService.programmed_at),callback:function ($$v) {_vm.$set(_vm.selectedService, "programmed_at", $$v)},expression:"selectedService.programmed_at"}})],1),_c('div',{staticClass:"my-4"},[_c('small',{staticClass:"text-san-juan font-bold"},[_vm._v("Hora")]),_c('vs-input',{staticClass:"w-full",attrs:{"type":"time","label":"Hora inicio"},model:{value:(_vm.selectedService.time),callback:function ($$v) {_vm.$set(_vm.selectedService, "time", $$v)},expression:"selectedService.time"}})],1),_c('ValidationProvider',{attrs:{"name":"Socio logístico","vid":"Socio logístico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full mb-4",attrs:{"label":"Socio logístico","placeholder":"Socio logístico","autocomplete":""},model:{value:(_vm.selectedService.deliverymanId),callback:function ($$v) {_vm.$set(_vm.selectedService, "deliverymanId", $$v)},expression:"selectedService.deliverymanId"}},_vm._l((_vm.deliverymen),function(item,index){return (item.data_status == 'verified')?_c('div',{key:index,staticClass:"items-select",attrs:{"text":item.user.first_name,"value":item.id}},[_c('vs-select-item',{attrs:{"value":item.id,"text":((item.code ? item.code : 'N/A') + " - " + (item.user.first_name + ' ' + item.user.last_name))}})],1):_vm._e()}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"my-4"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Franquicia","placeholder":"Franquicia","disabled":""},model:{value:(_vm.selectedService.franchiseName),callback:function ($$v) {_vm.$set(_vm.selectedService, "franchiseName", $$v)},expression:"selectedService.franchiseName"}})],1),_c('div',{staticClass:"my-4"},[_c('small',{staticClass:"text-san-juan font-bold"},[_vm._v("Color")]),_c('v-swatches',{staticClass:"simple-calendar__swatches",model:{value:(_vm.selectedService.color),callback:function ($$v) {_vm.$set(_vm.selectedService, "color", $$v)},expression:"selectedService.color"}},[_c('div',{staticClass:"simple-calendar__swatches-custom-trigger rounded-lg px-2 py-2",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"h-full w-full",style:('background-color:' + _vm.selectedService.color)})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }