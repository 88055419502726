import moment from 'moment';

export default {
  ADD_EVENT(state, event) {
    state.events.push(event);
  },
  SET_EVENTS(state, events) {
    state.events = events.map((service) => {
      let contactName = 'N/A';
      if (service.sender) {
        contactName = service.sender.name;
      }

      if (service.franchise) {
        contactName = `F: ${service.franchise.name}`;
      }

      return {
        ...service,
        title: `${moment(service.programmed_at).format(
          'HH:mm'
        )} ${contactName}`,
        startDate: service.programmed_at,
        style: 'background-color: ' + service.color,
      };
    });
  },
  SET_LABELS(state, labels) {
    state.eventLabels = labels;
  },
  UPDATE_EVENT(state, service) {
    const serviceIndex = state.events.findIndex((s) => s.id == service.id);
    let contactName = 'N/A';
    if (service.sender) {
      contactName = service.sender.name;
    }

    if (service.franchise) {
      contactName = `F: ${service.franchise.name}`;
    }

    const newService = Object.assign(state.events[serviceIndex], {
      ...service,
      title: `${moment(service.programmed_at).format('HH:mm')} ${contactName}`,
      startDate: service.programmed_at,
      style: 'background-color: ' + service.color,
    });
    return newService;
  },
  REMOVE_EVENT(state, eventId) {
    const eventIndex = state.events.findIndex((e) => e.id == eventId);
    state.events.splice(eventIndex, 1);
  },
};
