<template>
  <div class="simple-calendar" id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :items="simpleCalendarEvents"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-date="openAddNewEvent"
        @click-item="openEditEvent"
        @drop-on-date="eventDragged"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden">
              <!-- Add new event button -->
            </div>

            <!-- Current Month -->
            <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                  showDate | month
                }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
              </template>
            </div>
          </div>

          <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
            </div>
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- Detalle servicio -->
    <vs-prompt
      class="calendar-event-dialog"
      title="Detalle de servicio"
      accept-text="Guardar"
      cance-text="Descartar"
      button-cancel="border"
      @accept="editEvent"
      :is-valid="validForm"
      :active.sync="activePromptEditEvent"
    >
      <div v-if="selectedService.sender" class="mt-4 mb-5 flex flex-col">
        <h4 class="mb-2">Quién envía</h4>
        <span> <strong>Servicio Nº:</strong> {{ selectedService.id }} </span>
        <span>
          <strong>Cliente:</strong>
          {{ selectedService.senderName }}
        </span>
        <span>
          <strong>Teléfono:</strong>
          {{ selectedService.senderPhone }}
        </span>
        <span>
          <strong>Dirección:</strong>
          {{ selectedService.senderAddress }}
        </span>
      </div>

      <div
        v-if="
          selectedService.directions && selectedService.directions.length > 0
        "
        class="mt-4 mb-5 flex flex-col"
      >
        <h4 class="mb-2">Direcciones</h4>
        <span
          :key="index"
          v-for="(direction, index) in selectedService.directions"
        >
          <strong>Dirección {{ index + 1 }}:</strong>
          {{ direction.address }} - {{ direction.description }}.
        </span>
      </div>

      <div class="mt-4 mb-5 flex flex-col">
        <h4 class="mb-2">Datos generales</h4>
        <span>
          <strong>Factura: </strong>
          <span v-if="selectedService.invoice">
            {{ selectedService.invoice }}
          </span>
          <span v-else>N/A</span>
        </span>
        <span>
          <strong>Valor del pedido: </strong>
          <span v-if="selectedService.order_value">
            {{ selectedService.order_value | currencyFormat }}
          </span>
          <span v-else>N/A</span>
        </span>
      </div>

      <div class="my-4">
        <small class="text-san-juan font-bold">Fecha</small>

        <datepicker
          :language="langEs"
          name="start-date"
          :disabledDates="disabledDatesTo"
          v-model="selectedService.programmed_at"
        ></datepicker>
      </div>
      <div class="my-4">
        <small class="text-san-juan font-bold">Hora</small>
        <!-- <flat-pickr
          :config="configdateTimePicker"
          v-model="selectedService.time"
          class="w-full"
          label="Hora inicio"
          placeholder="00:00"
        /> -->
        <vs-input
          class="w-full"
          type="time"
          v-model="selectedService.time"
          label="Hora inicio"
        />
      </div>

      <ValidationProvider
        name="Socio logístico"
        vid="Socio logístico"
        v-slot="{ errors }"
        rules="required"
      >
        <vs-select
          label="Socio logístico"
          v-model="selectedService.deliverymanId"
          class="w-full mb-4"
          placeholder="Socio logístico"
          autocomplete
        >
          <div
            class="items-select"
            :text="item.user.first_name"
            :value="item.id"
            v-for="(item, index) in deliverymen"
            :key="index"
            v-if="item.data_status == 'verified'"
          >
            <vs-select-item
              :value="item.id"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
            />
          </div>
        </vs-select>
        <span class="text-danger text-sm">{{ errors[0] }}</span>
      </ValidationProvider>

      <div class="my-4">
        <vs-input
          label="Franquicia"
          placeholder="Franquicia"
          class="w-full"
          disabled
          v-model="selectedService.franchiseName"
        ></vs-input>
      </div>
      <div class="my-4">
        <small class="text-san-juan font-bold">Color</small>

        <v-swatches
          class="simple-calendar__swatches"
          v-model="selectedService.color"
        >
          <div
            class="simple-calendar__swatches-custom-trigger rounded-lg px-2 py-2"
            slot="trigger"
          >
            <div
              class="h-full w-full"
              :style="'background-color:' + selectedService.color"
            ></div>
          </div>
        </v-swatches>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import moduleCalendar from '@/store/calendar/moduleCalendar.js';
import { DELIVERYMEN } from '@/graphql/queries';
require('vue-simple-calendar/static/css/default.css');

import Datepicker from 'vuejs-datepicker';
import { en, he, es } from 'vuejs-datepicker/src/locale';
import moment from 'moment';
export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  data() {
    return {
      showDate: new Date(),
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
      },
      disabledFrom: false,
      // Event local
      selectedService: {},
      langHe: he,
      langEn: en,
      langEs: es,

      calendarView: 'month',

      activePromptAddEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: 'Mes',
          val: 'month',
        },
        {
          label: 'Semana',
          val: 'week',
        },
        {
          label: 'Año',
          val: 'year',
        },
      ],
    };
  },
  computed: {
    simpleCalendarEvents() {
      return this.$store.state.calendar.events;
    },
    validForm() {
      return (
        this.time != '' &&
        this.fecha != '' &&
        this.franquicia != '' &&
        this.color
        // !this.errors.has('event-url')
      );
    },

    disabledDatesTo() {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return { to: date };
    },
    calendarLabels() {
      return this.$store.state.calendar.eventLabels;
    },
    labelColor() {
      return (label) => {
        if (label == 'business') return 'success';
        else if (label == 'work') return 'warning';
        if (label == 'personal') return 'danger';

        if (label == 'none') return 'primary';
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  apollo: {
    deliverymen: {
      query: DELIVERYMEN,
    },
  },
  methods: {
    updateColor(e) {
      console.log(e.target.value);
    },

    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    clearFields() {
      this.fecha =
        this.numeroPedido =
        this.direccion =
        this.time =
        this.telefono =
        this.cliente =
        this.franquicia =
          '';
      this.id = 0;
      this.color = '#2ECC70';
    },
    promptAddNewEvent(date) {
      this.disabledFrom = false;
      this.addNewEventDialog(date);
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.startDate = date;
      this.endDate = date;
      this.fecha = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      const service = this.$store.getters['calendar/getEvent'](event.id);
      this.activePromptEditEvent = true;
      let sender;
      if (service.sender) {
        sender = {
          senderName: service.sender.name,
          senderPhone: service.sender.phone,
          senderAddress: service.sender.street,
        };
      }
      this.selectedService = {
        ...service,
        time: moment(service.programmed_at).format('HH:mm:ss'),
        color: service.color,
        ...sender,
        franchiseName: service.franchise ? service.franchise.name : '',
        deliverymanId: service.deliveryman ? service.deliveryman.id : '',
      };
    },
    editEvent() {
      this.$store.dispatch('calendar/editEvent', this.selectedService);
    },
    removeEvent() {
      this.$store.dispatch('calendar/removeEvent', this.id);
    },
    eventDragged(event, date) {
      this.$store.dispatch('calendar/eventDragged', {
        event: event,
        date: date,
      });
    },
  },
  created() {
    this.$store.registerModule('calendar', moduleCalendar);
    this.$store.dispatch('calendar/fetchEvents');
  },
  mounted() {},
  beforeDestroy() {
    this.$store.unregisterModule('calendar');
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.simple-calendar {
  &__swatches-custom-trigger {
    height: 38px;
    width: 100%;
    border: 1px solid #d7e2e8;
  }
}

.vue-swatches__trigger__wrapper {
  width: 100%;
}
</style>
